export const AMAZON_LOCATION_API_KEY =
    'v1.public.eyJqdGkiOiJmZmZkN2UwNC0wNmRmLTQ4OTctOWEzOC00NTA5N2NiODY5MGIifSUgWkDQ2dkshoBIAo_0q3syXuabEaV9KFm9vj6iO5WvXML2A0HDkRXoETBgOzQjQiygufvZfAzJWw4d0FX9rOzRLpTcqr3CoNuolH7JBn3y4SKcRwk4pf-g-LD6tUtYpYgv5UPSx2SjVzTJIgC7hVte0qV7AY6_bptW_8pkfVnbKo_S5LBVxWB2dPDKc_6tiqYllOjOtmugN23b1Qdkhj5Pm5xgBWMQvHhjhNodXIkrYy5RvCE0vvzqd4uD_4bmj45OjXVAu_SO7xyPmV-77gtWSgj5it44McnP40jhBc-GNtMYrGZlyItIrKpbUUslAPCsgVzXVpAN8uF89rec9ko.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx';
export const LOGAN_RDS_PROXY_LAMBDA_AUTHORIZATION_HEADER = 'Bearer 20240516';
export const LOGAN_RDS_PROXY_LAMBDA_ENDPOINT = 'https://omdmrhz5lb2nrbmodjtm5fxhqq0uevzh.lambda-url.us-east-1.on.aws/';
export const MAPLIBREDECKGLMAP_FETCH_DATA_ON_VIEWPORT_CHANGE = false;

export const WWF_BIOMES = {
    WWF_TEW_BIOME_01: { hex: '#008346', name: 'Tropical & Subtropical Moist Broadleaf Forests' },
    WWF_TEW_BIOME_02: { hex: '#9DCC00', name: 'Tropical & Subtropical Dry Broadleaf Forests' },
    WWF_TEW_BIOME_03: { hex: '#C4B72E', name: 'Tropical & Subtropical Coniferous Forests' },
    WWF_TEW_BIOME_04: { hex: '#015C31', name: 'Temperate Broadleaf & Mixed Forests' },
    WWF_TEW_BIOME_05: { hex: '#006E84', name: 'Temperate Conifer Forests' },
    WWF_TEW_BIOME_06: { hex: '#FFA8BB', name: 'Boreal Forests/Taiga' },
    WWF_TEW_BIOME_07: { hex: '#FAD505', name: 'Tropical & Subtropical Grasslands, Savannas & Shrublands' },
    WWF_TEW_BIOME_08: { hex: '#8F7C00', name: 'Temperate Grasslands, Savannas & Shrublands' },
    WWF_TEW_BIOME_09: { hex: '#67C7BF', name: 'Flooded Grasslands & Savannas' },
    WWF_TEW_BIOME_10: { hex: '#993E01', name: 'Montane Grasslands & Shrublands' },
    WWF_TEW_BIOME_11: { hex: '#C20088', name: 'Tundra' },
    WWF_TEW_BIOME_12: { hex: '#0275DC', name: 'Mediterranean Forests, Woodlands & Scrub' },
    WWF_TEW_BIOME_13: { hex: '#FFA405', name: 'Deserts & Xeric Shrublands' },
    WWF_TEW_BIOME_14: { hex: '#FFCC99', name: 'Mangroves' },
    WWF_TEW_BIOME_98: { hex: '#000000', name: 'Ocean' },
    WWF_TEW_BIOME_99: { hex: '#000000', name: 'Ocean' },
};

export const REGION_COUNTRIES = {
    'Africa': [
        'AGO',
        'BDI',
        'BEN',
        'BFA',
        'BWA',
        'CAF',
        'CIV',
        'CMR',
        'COD',
        'COG',
        'COM',
        'CPV',
        'DJI',
        'DZA',
        'EGY',
        'ERI',
        'ESH',
        'ETH',
        'GAB',
        'GHA',
        'GIN',
        'GMB',
        'GNB',
        'GNQ',
        'KEN',
        'LBR',
        'LBY',
        'LSO',
        'MAR',
        'MDG',
        'MLI',
        'MOZ',
        'MRT',
        'MUS',
        'MWI',
        'MYT',
        'NAM',
        'NER',
        'NGA',
        'REU',
        'RWA',
        'SDN',
        'SEN',
        'SLE',
        'SOM',
        'SSD',
        'STP',
        'SWZ',
        'SYC',
        'TCD',
        'TGO',
        'TUN',
        'TZA',
        'UGA',
        'ZAF',
        'ZMB',
        'ZWE',
    ],
    'Antarctica': ['ATF', 'BVT', 'HMD'],
    'Asia': [
        'AFG',
        'ARE',
        'ARM',
        'AZE',
        'BGD',
        'BHR',
        'BRN',
        'BTN',
        'CHN',
        'CYP',
        'GEO',
        'HKG',
        'IDN',
        'IND',
        'IOT',
        'IRN',
        'IRQ',
        'ISR',
        'JOR',
        'JPN',
        'KAZ',
        'KGZ',
        'KHM',
        'KOR',
        'KWT',
        'LAO',
        'LBN',
        'LKA',
        'MAC',
        'MMR',
        'MNG',
        'MYS',
        'NPL',
        'OMN',
        'PAK',
        'PHL',
        'PRK',
        'PSE',
        'QAT',
        'RUS',
        'SAU',
        'SGP',
        'SYR',
        'THA',
        'TJK',
        'TKM',
        'TLS',
        'TUR',
        'TWN',
        'UZB',
        'VNM',
        'YEM',
    ],
    'Europe': [
        'ALB',
        'AND',
        'AUT',
        'BEL',
        'BGR',
        'BIH',
        'BLR',
        'CHE',
        'CZE',
        'DEU',
        'DNK',
        'ESP',
        'EST',
        'FIN',
        'FRA',
        'FRO',
        'GBR',
        'GIB',
        'GRC',
        'HRV',
        'HUN',
        'IRL',
        'ISL',
        'ITA',
        'LIE',
        'LTU',
        'LUX',
        'LVA',
        'MCO',
        'MDA',
        'MKD',
        'MLT',
        'MNE',
        'NLD',
        'NOR',
        'POL',
        'PRT',
        'ROU',
        'SJM',
        'SMR',
        'SRB',
        'SVK',
        'SVN',
        'SWE',
        'UKR',
        'VAT',
    ],
    'North America': [
        'ANT',
        'ABW',
        'AIA',
        'ATG',
        'BHS',
        'BLZ',
        'BMU',
        'BRB',
        'CAN',
        'CRI',
        'CUB',
        'CYM',
        'DMA',
        'DOM',
        'GLP',
        'GRD',
        'GRL',
        'GTM',
        'HND',
        'HTI',
        'JAM',
        'KNA',
        'LCA',
        'MEX',
        'MSR',
        'MTQ',
        'NIC',
        'PAN',
        'PRI',
        'SLV',
        'TCA',
        'TTO',
        'USA',
        'VCT',
        'VGB',
        'VIR',
    ],
    'Oceania': [
        'ASM',
        'AUS',
        'CCK',
        'COK',
        'CXR',
        'FJI',
        'FLK',
        'FSM',
        'GUM',
        'KIR',
        'MNP',
        'NCL',
        'NFK',
        'NIU',
        'NRU',
        'NZL',
        'PCN',
        'PLW',
        'PNG',
        'PYF',
        'SLB',
        'TON',
        'VUT',
        'WSM',
    ],
    'South America': ['ARG', 'BOL', 'BRA', 'CHL', 'COL', 'ECU', 'GUF', 'GUY', 'PER', 'PRY', 'SUR', 'URY', 'VEN'],
};
